import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Menu32, PhoneVoice32 } from "@carbon/icons-react"

const StyledNav = styled.nav`
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #051937;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  .button {
    background-color: #284778;
    padding: 0.1rem 0.3rem;
    border-radius: 4px;
    margin: 0.3rem;
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }
  }
  @media (min-width: 800px) {
    justify-content: flex-end;
    span {
      padding: 0 1rem;
    }
  }
`

const Nav = () => (
  <StyledNav>
    <span>Services</span>
    <span>Find Us</span>
    <span className="button">
      <a target="_blank" href="tel:+16782702091">
        <PhoneVoice32 />
        Call Us
      </a>
    </span>
  </StyledNav>
)

export default Nav
