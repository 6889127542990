import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { siteName } from "../info"

const StyledFooter = styled.footer`
  min-height: 4rem;
  padding: 1rem 0;
  background-color: #000000;
  color: white;
  div {
    border-top: 1px solid gray;
    padding: 1rem 0;
    p {
      font-size: 0.9rem;
      line-height: 1.2;
      margin: 0.4rem 1rem;
      font-weight: 300;
    }
  }
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  ul {
    list-style: none;
    li {
      margin: 0.2rem;
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }
    p {
      width: 50%;
      margin: auto;
      border-bottom: 1px solid gray;
      text-align: center;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <ul>
        <p>Pages</p>
        <li>
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </li>
      </ul>
      <div>
        <p>
          {siteName} is a free referral service that helps users connect with
          local contractors. All contractors are independent and {siteName} does
          not warrant or guarantee any work performed. It is the responsibility
          of the user to verify the contractor they hire has the necessary
          license, certifications and insurance required for the work being
          performed. Any third party products, brands or trademarks listed above
          are the sole property of their respective owner. No affiliation or
          endorsement is intended or implied.
        </p>
        <p>
          By providing your email or phone number to {siteName}, you authorize
          our home service partners or companies to call or text you for any
          purpose, including marketing purposes. They may contact you by live,
          autodialed, pre-recorded, or artificial voice call, and/or SMS text,
          even if your phone number is on any national or state "Do Not Call"
          list. You are not obligated to make any sort of purchase and standard
          message and data rates may apply. You acknowledge that this consent
          may be removed at your request, but that until such consent is
          revoked, you may receive emails or calls from {siteName} or our third
          party partners.
        </p>
      </div>
    </StyledFooter>
  )
}

export default Footer
