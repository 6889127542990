import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { ChevronRight32, Send32 } from "@carbon/icons-react"
import styled from "styled-components"
import { area } from "../info"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const StyledSimpleForm = styled.form`
  width: 100%;
  margin: 3rem auto;
  line-height: 0.9;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: #0043ce;
    color: #ffffff;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    box-sizing: border-box;
    height: 52px;
  }
  label {
    font-size: 0.6rem;
  }
  @media (min-width: 800px) {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
const Input = styled.input`
  max-width: 250px;
  border: ${props => (props.errror ? `2px solid red` : `2px solid #0043ce`)};
  padding: 0.5rem;
  margin: 0.4rem 0;
  box-sizing: border-box;
  height: 54px;
  @media (min-width: 800px) {
    max-width: 400px;
  }
`

const StyledScheduleForm = styled.form`
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgb(50 50 93 / 25%),
    0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%);
  margin: 0 auto;
  padding: 1rem;
  h3 {
    margin-bottom: 1rem;
  }
  label {
    font-size: 0.8rem;
    line-height: 1;
    font-weight: 600;
  }
  .underline {
    text-decoration: underline;
  }
  .checkbox {
    color: #0043ce;
    display: block;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    border-radius: 0 0.125rem 0.125rem;
    padding: 0.4rem;
    width: 90%;
    border: 1px solid #e1e1e1;
  }
  input[type="checkbox"] {
    margin-right: 0.4rem;
    border: 2px solid #0043ce;
  }
  div {
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      text-align: center;
    }
  }
  button {
    background-color: #0043ce;
    color: #ffffff;
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
    margin: 0.3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    &:hover {
      box-shadow: 0 30px 60px -12px rgb(50 50 93 / 25%),
        0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%);
    }
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const HeaderForm = () => {
  const { register, handleSubmit, watch, errors } = useForm()

  return (
    <form>
      <input type="text" name="name" placeholder="name" />
      <input type="text" name="email" placeholder="email" />
      <input type="text" name="phone-model" placeholder="phone model" />
      <input type="text" name="damage-type" placeholder="damage type" />
    </form>
  )
}

export const SimpleForm = () => {
  const { register, handleSubmit, watch, errors } = useForm()
  const onSubmit = subData => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "simple-form",
        ...subData,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => console.error("error:", error))
  }

  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    return (
      <StyledSimpleForm onSubmit={handleSubmit(onSubmit)}>
        <h3>Thank You!</h3>
      </StyledSimpleForm>
    )
  }

  return (
    <StyledSimpleForm
      onSubmit={handleSubmit(onSubmit)}
      name="simple-form"
      data-netlify="true"
    >
      <label>
        We'll connect you with a handyman in {area}, no obligations.
      </label>
      <div>
        <input
          type="hidden"
          className="hidden"
          name="form-name"
          value="simple-form"
        />
        <Input
          error={errors.email}
          type="email"
          name="email"
          defaultValue=""
          placeholder="Your email"
          ref={register({ required: true })}
        />
        <button type="submit">
          <Send32 />
        </button>
      </div>
    </StyledSimpleForm>
  )
}

export const ScheduleForm = ({ service }) => {
  const [isSubmitted, setSubmitted] = useState(false)
  const { register, handleSubmit, watch, errors } = useForm()

  const onSubmit = subData => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "long-form",
        ...subData,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error))
  }

  return (
    <StyledScheduleForm onSubmit={handleSubmit(onSubmit)} name="schedule-form">
      <h3>New Request</h3>
      <p>
        Fill in the form below and we will contact to you about{" "}
        <span className="underline">{service}</span> ASAP.
      </p>
      <input
        type="hidden"
        className="hidden"
        name="form-name"
        value="schedule-form"
      />
      <div>
        <label>Name</label>
        <input
          type="text"
          name="full-name"
          defaultValue=""
          placeholder="Full name"
          ref={register({ required: true })}
        />
      </div>
      <div>
        <label>Zip code</label>
        <input
          type="number"
          name="zip-code"
          defaultValue=""
          placeholder="Zip or Postal code"
          ref={register({ required: true })}
        />
      </div>
      <div>
        <label>Contact</label>
        <input
          type="email"
          name="email"
          defaultValue=""
          placeholder="E-mail"
          ref={register({ required: false })}
        />
        <p>OR</p>
        <input
          type="number"
          name="phone"
          defaultValue=""
          placeholder="Phone Number"
          ref={register({ required: false })}
        />
        <span className="checkbox">
          <input
            type="checkbox"
            name="call-me"
            defaultValue={"false"}
            ref={register}
          />
          <label htmlFor="call-me">I prefer to be called</label>
        </span>
      </div>
      <div>
        <label>Details (optional)</label>
        <textarea
          placeholder="Anything that comes to mind."
          name="work-desc"
        ></textarea>
      </div>
      <input
        type="hidden"
        name="service"
        defaultValue={service}
        ref={register}
      />

      <button type="submit">
        Get Price Quote <ChevronRight32 />
      </button>
    </StyledScheduleForm>
  )
}
